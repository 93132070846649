.mainWrapper {
  display: flex;
  grid-gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}
.Events-container {
  width: 100%;
  margin: 0 auto;
}
.Events-header {
  display: flex;
  justify-content: flex-end;
}
.add-taccount-btn {
  margin-bottom: 7px;
}
